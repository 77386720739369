import {html} from "./utils/environment";

import datetime from "./components/datetime";

window.addEventListener('DOMContentLoaded', () => {
    console.log("App JS Started");

    html.classList.add("is-loaded");
    html.classList.add("is-ready");
    html.classList.remove("is-loading");

    datetime();
});
