////
/// ============================================================================
/// COMPONENT / Datetime
/// ============================================================================
///
/// @group components
////

export default () => {
    let datetime = document.querySelectorAll('.js-datetime');

    if (!datetime) {
        return;
    }

    function showDateTime() {
        let date = new Date();

        let dayList = [
            "Sun",
            "Mon",
            "Tue",
            "Wed",
            "Thu",
            "Fri",
            "Sat"]
        ;
        let monthNames = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ];

        let dayName = dayList[date.getDay()];
        let monthName = monthNames[date.getMonth()];
        let today = `${dayName}, ${monthName} ${date.getDate()}, ${date.getFullYear()}`;

        let hour = date.getHours();
        let min = date.getMinutes();
        let sec = date.getSeconds();

        let time = hour + ":" + min + ":" + sec;

        datetime.forEach((content) => {
            content.innerText = `Today is  ${today}. Time is ${time}`;
        })
    }

    setInterval(showDateTime, 1000);
};
